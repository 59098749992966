import React, { useCallback, useEffect, useState } from 'react';
import { default as NextHead } from 'next/head';
import Script from 'next/script';
import { styled, useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { getCdnUrl } from '@bladebinge/web-service-common/src/utils/get-cdn-url';
import { useMe } from '../context/me/me-context';
import { useSearchFilterContext } from '../context/search-filter/search-filter-context';
import { FilterDrawer } from './search-filters/FilterDrawer';
import { APP_BAR_HEIGHT_PX, MOBILE_SEARCH_BAR_HEIGHT_PX, Navigation } from './Navigation';
import { SiteFooter } from './SiteFooter';
import { ErrorBoundary } from './ErrorBoundary';
import { ReConfirmUserTermsModal } from './FormComponents/ReConfirmUserTermsModal';

const ContentComponent = styled(Grid)(({ theme }) => ({
    flexGrow: 1,
    minHeight: '80vh',
    margin: `${APP_BAR_HEIGHT_PX + 12}px auto 0`,
    padding: theme.spacing(1),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.short
    }),
    [theme.breakpoints.down('sm')]: {
        margin: `${APP_BAR_HEIGHT_PX + 5}px 0 0 0`
    },
    backgroundColor: theme.palette.background.paper,
    ...(theme.palette.mode === 'dark'
        ? {}
        : {
              backgroundImage: `url(${getCdnUrl('/background/grey_texture3.jpg')})`,
              backgroundRepeat: 'repeat'
          })
}));

const WrapperComponent = styled(Grid)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    backgroundImage: `url(${getCdnUrl('/background/black_texture.jpg')})`,
    backgroundRepeat: 'repeat'
}));

type Props = {
    readonly children?: React.ReactElement | null;
    readonly simplify?: boolean;
    readonly title: string;
};

export const Layout: React.FunctionComponent<Props> = ({ children, simplify = false, title }: Props) => {
    const theme = useTheme();
    const { id: meId } = useMe();
    const { showMobileSearchBar } = useSearchFilterContext();
    const isProduction = process.env.NODE_ENV === 'production';
    const isProdLifecycle = process.env.ENV_CDK_LIFECYCLE === 'prod';

    const [clarityCookiesActive, setClarityCookiesActive] = useState<boolean>(false);

    const renderNullFallback = useCallback(() => null, []);

    useEffect(() => {
        // @ts-expect-error
        if (meId && isProduction && !clarityCookiesActive && typeof window?.clarity === 'function') {
            setClarityCookiesActive(true);
            // @ts-expect-error
            window.clarity('consent');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isProduction, meId, setClarityCookiesActive]);

    const bladebingeMetaDescription =
        'Bladebinge.com - The best online marketplace for knife collectors, makers and retailers. Buy and sell used knives, custom knives, direct-from-manufacturer, or browse the secondary knife market. - Buy, Sell, Rate, Repeat';
    return (
        <>
            <NextHead>
                <meta charSet="utf8" />
                {/* PWA primary color */}
                <meta name="theme-color" content={theme.palette.primary.main} />
                <meta httpEquiv="Content-Type" content="text/html;charset=utf-8" />
                <title>{title}</title>
                <link rel="icon" type="image/png" href={getCdnUrl('/logos/bb_favicon.png')} />
                <meta httpEquiv="Content-Type" content="text/html;charset=utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="copyright" content="Bladebinge is a registered trademark of Bladebinge™" />
                <meta key="description" name="description" content={bladebingeMetaDescription} />
                <meta key="og:url" property="og:url" content={process.env.ENV_UI_SERVER_URL} />
                <meta key="og:title" property="og:title" content={title} />
                <meta key="og:description" property="og:description" content={bladebingeMetaDescription} />
                <meta key="og:image" property="og:image" content={getCdnUrl('/logos/bladebinge_black_1200_630.png')} />
                <meta key="og:image:width" property="og:image:width" content="1200" />
                <meta key="og:image:height" property="og:image:height" content="630" />
            </NextHead>
            <Grid container direction="column" justifyContent="space-between">
                <Grid item>
                    <ErrorBoundary renderFallback={renderNullFallback}>
                        <Navigation simplify={simplify} />
                        <FilterDrawer />
                    </ErrorBoundary>
                </Grid>
                <WrapperComponent container justifyContent="space-around">
                    <ContentComponent
                        id="bb_layout_content_component"
                        item
                        xs={12}
                        sm={11}
                        md={11}
                        lg={11}
                        xl={11}
                        sx={{
                            overflowX: 'scroll',
                            maxWidth: '98vw',
                            ...(showMobileSearchBar && {
                                [theme.breakpoints.down('md')]: {
                                    margin: `${APP_BAR_HEIGHT_PX + MOBILE_SEARCH_BAR_HEIGHT_PX + 12}px auto 0`
                                }
                            })
                        }}
                    >
                        <ErrorBoundary>{children}</ErrorBoundary>
                    </ContentComponent>
                </WrapperComponent>
                <Grid item>
                    <ErrorBoundary renderFallback={renderNullFallback}>
                        <SiteFooter />
                        <ReConfirmUserTermsModal />
                    </ErrorBoundary>
                </Grid>
            </Grid>
            {
                // only show the mailerlite prompt on production due to interference with playwright testing
                !meId && isProdLifecycle && (
                    <Script strategy="lazyOnload" src={getCdnUrl('/vendor/mailerlite-emaillist-prompt.js')} />
                )
            }
            {isProduction && (
                <>
                    <Script strategy="lazyOnload" src={getCdnUrl('/vendor/intercom-widget.js')} />
                    <Script strategy="lazyOnload" src={getCdnUrl('/vendor/clarity.js')} />
                </>
            )}
            {process?.env?.ENV_BUILD_VERSION && (
                <div
                    id="app_build_version"
                    // eslint-disable-next-line
                    dangerouslySetInnerHTML={{ __html: `<!-- BUILD VERSION(${process?.env?.ENV_BUILD_VERSION}) -->` }}
                />
            )}
        </>
    );
};
